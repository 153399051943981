<template>
  <div class="container">
    <form @keydown.enter="forgotPassword()">
      <h1>Zabudnuté heslo</h1>
      <h5>
        Zadajte, prosím váš e-mail, s ktorým ste sa registrovali. Následne vám
        odošleme na e-mail inštrukcie na resetovanie hesla
      </h5>

      <p class="callout">
        !!! SKONTROLUJTE, či Vám e-mail neprišiel do spamu, koša alebo reklám,
        prípadne, či Váš školský IT administrátor neblokuje naše e-maily.
      </p>

      <div class="row">
        <div class="form-group col-12">
          <label for="emailAddress">Emailová adresa</label>
          <input
            v-model="credentials.email"
            type="email"
            class="form-control"
            id="emailAddress"
            placeholder="Zadajte email"
            autocomplete="email"
          />
        </div>
      </div>

      <b-button
        variant="primary"
        @click.prevent="forgotPassword()"
        :disabled="$toast.isLoading()"
        >Pokračovať</b-button
      >
    </form>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

export default {
  data() {
    return {
      credentials: {
        email: "",
      },
    };
  },

  created() {
    if (this.$store.getters["auth/isLoggedIn"]) this.$router.push("/profil");
  },

  methods: {
    async forgotPassword() {
      this.$toast.clear_loading();

      try {
        await this.$store.dispatch("auth/forgotPassword", {
          email: this.credentials.email,
        });

        this.$toast.stopLoading();
        this.$toast.success(
          "Na uvedený email boli odoslane inštrukcie ohľadom resetovania hesla."
        );
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 5px !important;
}

h5 {
  margin-bottom: 30px;
}

.callout {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: red;
}
</style>
